<template>
 <v-overlay v-if="isLoading" :value="isLoading" z-index="1000">

    <div class="loading-overlay">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="80"
        :width="12"
      ></v-progress-circular>
      <p class="loading-message">{{ loadingMsg }}</p>
    </div>
  </v-overlay>
  <!-- <v-row
        v-if="isLoading"
        class="d-flex justify-center align-center"
        style="height: 300px;"
      >
        <v-progress-circular indeterminate color="primary"
        :size="80"
        :width="12"
          ><br /><br /><small class="text-center pt-6" style="margin-top: 105px !important"
            >{{ loadingMsg }}</small
          ></v-progress-circular
        >
      </v-row>-->
</template>

<script>
export default {
  name: "LoadingOverlay",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMsg: {
      type: String,
      default: "Cargando...",
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /*background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  color: white;
}
.loading-message {
  margin-top: 16px;
  font-size: 16px;
  text-align: center;
}
</style>
