<template>
  <v-container
    v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_REGISTRALES)"
    id="crud"
    fluid
    tag="section"
    class="px-6 mx-0"
  >
    <div v-if="$store.state.carrito.data.length == 0">
      <div
        style="height: 320px"
        class="carritoMsg__div px-2 d-flex flex-column justify-center align-items-center"
      >
        <div style="position: relative; display: inline-block">
          <v-icon
            color="text-center"
            style="font-size: 5rem; top: 0; left: 45%; pointer-events: none"
            >mdi-cart-outline</v-icon
          >

          <v-btn
            class="text-center primary"
            dark
            elevation="0"
            fab
            icon
            x-small
            style="position: absolute; top: 0; left: 47%; pointer-events: none"
          >
            <span style="font-size: 14px; padding: 5px">0</span>
          </v-btn>
        </div>
        <br />
        <p
          style="line-height: 1; font-weight: 500"
          class="display-1 text-center"
        >
          Tu carrito está vacio
        </p>
        <small style="line-height: 1; color: #999" class="text-center">
          Actualmente no hay productos en su carrito.<br />Puede seguir
          comprando y agregar los productos deseados.  <br><br><br>
          <v-btn
                width="150"
                color="success"
                class="text-center capitalize-first custom-btn"
                @click="$router.push({ name: 'elementos_registrales' })"
              >
                Ver productos
              </v-btn>
        </small>


      </div>
    </div>
    <div v-else>
      <v-row class="my-2 px-0 mx-0">
        <v-col cols="auto" md="12" class="px-0 mx-0">
          <v-alert
            outlined
            type="info"
            colored-border
            color="info"
            elevation="2"
          >
            <p class="info--text py-0 my-0" style="font-weight: 400">
              Los métodos de pago varían según los productos en tu carrito.
              Selecciona una opción para cada categoría. Si eliges 'Cheque Banco
              Nación', se te solicitarán los datos al presionar 'Confirmar’
            </p>
          </v-alert>
        </v-col>

        <v-col cols="auto" md="12" class="">
          <v-badge
            v-if="$store.state.carrito.cantItem > 0"
            color="primary"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ $store.state.carrito.cantItem }}</span>
            </template>

            <v-icon class="icon-25">mdi-cart-outline</v-icon>
          </v-badge>
          <v-badge v-else overlap bordered>
            <template v-slot:badge>
              <span>0</span>
            </template>

            <v-icon style="color: #000; font-size: 30px"
              >mdi-cart-outline</v-icon
            >
          </v-badge>
          <span class="ml-3 text-h6" style="">Carrito</span>
        </v-col>
      </v-row>

      <Loading  v-if="isLoading" :isLoading="isLoading" :loadingMsg="loadingMsg" />

      <v-row class="my-0 px-0 mx-0" v-else>
        <v-col cols="auto" md="9" class="ma-0 pa-0 pr-2">
          <div
            v-for="(tipo, index) in carritoTiposElementos"
            :key="tipo.TipoElementosId"
            class="mb-4"
          >
            <v-card class="bg-secondary">
              <v-card-title style="border-bottom: 1px solid #ddd">
                <v-row class="py-0 px-0" style="height: 68px">
                  <v-col
                    cols="auto"
                    style="width: 5%"
                    class="px-0 mx-0 py-0 mt-3"
                  >
                    <v-btn
                      x-small
                      @click="toggleCard(index)"
                      class="btn-panel-mas-menos ml-1"
                    >
                      <v-icon style="font-size: 1.2rem">{{
                        cardStates[index] ? "mdi-minus" : "mdi-plus"
                      }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    style="width: 50%"
                    class="px-0 mx-0 font-weight-bold py-0 mt-3 text-left"
                    >{{ tipo.TipoElementosDesc }}</v-col
                  >
                  <v-col
                    cols="auto"
                    md="1"
                    class="px-0 mx-0 font-weight-bold py-0 mt-4"
                  >
                    <div
                      class="mdi-credit-card-2 ml-3"
                      style="height: 30px"
                    ></div>
                  </v-col>
                  <v-col
                    cols="auto"
                    md="4"
                    class="text-end px-0 mx-0 font-weight-bold"
                  >
                    <v-select
                      :items="computedPaymentMethods(tipo)"
                      label="Método de pago"
                      dense
                      solo
                      chips
                      x-small
                      class="small-select"
                      v-model="tipo.MetodoPago"
                      item-text="desc"
                      item-value="code"
                      @change="changeMethodPayment(tipo)"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :input-value="data.selected"
                          color="primary"
                          dark
                          style="padding: 13px 15px !important"
                          small
                          close-icon
                        >
                          {{ data.item.desc }}
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-title>

              <!-- Contenido desplegable de cada tarjeta -->
              <v-card-text v-if="cardStates[index]" class="px-2">


                      <!--componente cheque-->

                      <form-cheque
                      :tipo="tipo"
                      :carritoTiposElementos="carritoTiposElementos"

                      ></form-cheque>
                      <!--componente cheque-->



                      <div
                        v-for="(item, indexItem) in carritoOrdenado.filter(
                          (el) => el.TipoElementosId === tipo.TipoElementosId
                        )"
                        :key="item.ItemCode+'-'+item.db"
                        class="mt-5 mb-4 px-0 fondo-blanco"
                      >
                        <v-row
                          class="px-2 mx-0"
                          style="border-bottom: 1px solid #ddd"
                        >
                          <v-col
                            cols="auto"
                            style="width: 5%"
                            class="px-0 ml-0 py-0 mt-3"
                          >
                            <v-btn
                              x-small
                              class="btn-panel-mas-menos"
                              @click="toggleCardItem(indexItem)"
                              style="background: #444; padding: 0px"
                                v-if="item.Aportes"
                            >
                              <v-icon
                                style="font-size: 0.8rem; margin: 0px; padding: 0px"
                              >
                                {{
                                  cardStatesItem[indexItem] ? "mdi-minus" : "mdi-plus"
                                }}
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-2 mx-0 font-weight-bold"
                            >{{ item.ItemCode }}</v-col
                          >
                          <v-col cols="auto" style="width: 5%" class="px-0 mx-0">
                            <span
                              class="custom-badge"
                              :class="{
                                'badge-blue': item.db.substring(4, 6) === 'AC',
                                'badge-green': item.db.substring(4, 6) === 'MJ',
                              }"
                              >{{ item.db.substring(4, 6) }}</span
                            >
                          </v-col>
                          <v-col
                            cols="auto"
                            md="3"
                            class="px-0 mx-0 font-weight-bold text-left"
                            >{{ item.ForeignName | capitalizeFirst }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >{{ "$ " + $formatMoney(item.priceList.Price) }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >x{{ item.unidades }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold info--text text-right"
                            >{{
                              "$ " +
                              $formatMoney(item.priceList.Price * item.unidades)
                            }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-0 mx-0 font-weight-bold text-right"
                          >
                            <v-icon
                              v-if="item.ItemCode !== 'FLE' && item.ItemCode !== 'GB' && item.ItemCode !== 'GT'"
                              small
                              class="mr-2 my-0 py-0"
                              title="Eliminar"
                              @click="dialogEliminar(item.ItemCode)"
                              style="font-size: 20px; color: red"
                            >
                              mdi-delete
                            </v-icon>

                            <dialog-confirmar
                              v-model="dialogs[item.ItemCode]"
                              @input="dialog = $event"
                              :title="`¿Estás seguro de que deseas eliminar el producto <br>${capitalizeFirst(item.ForeignName)}?`"
                              info="Esta acción no se puede deshacer."
                              icon="mdi-alert-circle-outline"
                              color="#F44336"

                              @confirm="removeItem(item)"
                            />
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="item.Aportes && cardStatesItem[indexItem]"
                          class="px-2 mx-0"
                          style="border-bottom: 1px solid #ddd"
                        >
                          <v-col
                            cols="auto"
                            style="width: 5%"
                            class="px-0 ml-0 py-0 mt-3"
                          >
                          </v-col>
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-2 mx-0 font-weight-bold"
                          ></v-col>
                          <v-col cols="auto" style="width: 5%" class="px-0 mx-0">
                          </v-col>
                          <v-col
                            cols="auto"
                            md="3"
                            class="px-0 mx-0 font-weight-bold text-left"
                            >{{
                              item.Aportes.aporteItemName | capitalizeFirst
                            }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >{{
                              "$ " + $formatMoney(item.Aportes.aporteItemPrice)
                            }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="1"
                            class="px-0 mx-0 font-weight-bold text-right"
                            >x{{ item.unidades }}</v-col
                          >
                          <v-col
                            cols="auto"
                            md="2"
                            class="px-0 mx-0 font-weight-bold info--text text-right"
                            >{{
                              "$ " +
                              $formatMoney(
                                item.Aportes.aporteItemPrice * item.unidades
                              )
                            }}</v-col
                          >
                        </v-row>

                      </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="carrito__div pa-0 ma-0" style="height: 20vh">


          </div>
        </v-col>
        <v-col cols="auto" md="3" class="ma-0 pa-0">
          <v-row class="pt-0 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start">
              <span class="ml-0 text-h6" style="">Resumen</span></v-col
            >
          </v-row>

          <v-row v-if="$store.state.carrito.sumasPorTipo[1]" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Subtotal de elementos registrales:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.sumasPorTipo[1]
                  ? $formatMoney($store.state.carrito.sumasPorTipo[1].subtotal)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row  v-if="$store.state.carrito.sumasPorTipo[2]" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Subtotal de elementos aduana:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.sumasPorTipo[2]
                  ? $formatMoney($store.state.carrito.sumasPorTipo[2].subtotal)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row  v-if="$store.state.carrito.sumasPorTipo[3]" class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >Subtotal Adquirir saldo corriente:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.sumasPorTipo[3]
                  ? $formatMoney($store.state.carrito.sumasPorTipo[3].subtotal)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row v-if=$store.state.carrito.gastos.label class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
              >{{ $store.state.carrito.gastos.label }}:</v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.subtotal
                  ? $formatMoney(parseFloat($store.state.carrito.gastos.total ))
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row class="pt-4 my-0 ml-2" style="font-size: 13px; color: #444">
            <v-col cols="12" md="7" class="py-0 text-start"
            >Envío por correo:
            </v-col
            >

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.gastosEnvio
                  ? $formatMoney($store.state.carrito.gastosEnvio)
                  : ""
              }}
            </v-col>
          </v-row>

          <hr
            style="border: none; height: 1px; background-color: #ccc"
            class="mx-1"
          />
          <v-row
            class="py-4 my-0 ml-2 py-2"
            style="font-size: 13px; color: #000; font-weight: 600"
          >
            <v-col cols="12" md="7" class="py-0 text-start"> Total</v-col>

            <v-col cols="12" md="5" class="py-0 text-end pr-4 mr-0">
              ${{
                $store.state.carrito.total
                  ? $formatMoney($store.state.carrito.total)
                  : ""
              }}
            </v-col>
          </v-row>

          <v-row class="py-1 my-0 mx-0">
            <v-col cols="12" md="12" class="mx-auto">
              <v-btn
                block
                color="btnPrimary"
                class="capitalize-first custom-btn"
                @click="dialogValidate"
              >
                Confirmar
              </v-btn>
              <dialog-validate
                v-model="dialogs['carritoValidateMetodosPago']"
                @input="dialog = $event"
                title="Método de pago requerido"
                info="Selecciona un método de pago para cada categoría antes de continuar con la compra."
                icon="mdi-alert-circle-outline"
                color="#ffc107"
              />
              <dialog-validate
                v-model="dialogs['validateCheque']"
                 @input="dialog = $event"
                title="Datos Incompleto"
                info="Completa los datos del cheque son requeridos para finalizar tu compra."
                icon="mdi-alert-circle-outline"
                color="#ffc107"
              />

              <dialog-confirmar
                v-model="dialogs['carritoFacturas']"
                @input="dialog = $event"
                title="¿Estás seguro de que desea generar las facturas?"
                info="Esta acción no se puede deshacer."
                icon="mdi-alert-circle-outline"
                color="#0078D4"

                @confirm="viewFacturas()"
              />
              <v-btn
                block
                color="danger"
                class="mt-1 capitalize-first custom-btn"
                @click="dialogVaciar"
              >
                Vaciar Carrito
              </v-btn>


              <dialog-confirmar
                v-model="dialogs['carritoVaciar']"
                @input="dialog = $event"
                title="Vaciar carrito"
                info="¿Estás seguro de que quieres vaciar todos los articulos de tu carrito? Esta acción no se puede deshacer."
                icon="mdi-cart-outline"
                color="#F44336"

                @confirm="carritoVaciar()"
              />


            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>

  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";

import DialogValidate from "@/views/dialogs/Validate.vue";
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import Loading from "@/views/components/Loading.vue";
import FormCheque from "@/views/carrito/FormCheque.vue";

export default {
  components: {
    Loading,
    DialogValidate,
    DialogConfirmar,
    FormCheque,

  },

  data: (vm) => ({
    snackbar: false,
    text: "Registro Insertado",
    route: "aportes",
    routeFactura: "facturas",
    color: "success",
    timeout: 4000,
    isLoading: true,
    loadingMsg:"obteniendo datos...",
    carritoOrdenado: [],
    panelStates: [],
    panelStatesItem: [],
    dialogs: {
      carritoVaciar: false,
      carritoValidateMetodosPago: false,
      validateCheque: false,
      carritoFacturas: false,
    },
    cardStates: [], // Controla la expansión de cada tarjeta de tipo
    cardStatesItem: [],



  }),



  mounted() {
    this.loadData();
    console.log("Componente " + this.baseUrl + " creado");
  },

  computed: {
    ...mapState({
      carrito: (state) => state.carrito,
    }),
    paymentMethods() {
      return this.$store.getters.getPaymentMethods;
    },
    computedPaymentMethods() {
      return (tipo) => {
        return this.$store.getters.getPaymentMethods.filter((method) => {
          // Si es tipo aduana y el método es "SALDO", lo excluye
          if ((tipo.TipoElementosId === 2 || tipo.TipoElementosId === 3) && method.code === 'SALDO') {
            return false;
          }
          // Devuelve todos los demás métodos
          return true;
        });
      };
    },
    /*carritoOrdenado() {
      return this.carrito.data
        .slice()
        .sort((a, b) => a.TipoElementosId - b.TipoElementosId);
    },*/
  },

  methods: {

    async fetchSaldoAFavor() {
      try {
        const cliente = this.$store.state.user.sapProperties.CardCode;
        const bases =  this.$db("ACARA")+','+this.$db("MJ");

        const saldos = await this.$store.dispatch('saldoCC/fetchItemsSaldo', {
          modo: 'consultar',
          cliente,
          bases,
        });

        if (saldos) {
          this.validarSaldoAFavor(saldos);
        } else {
          console.error('No se pudieron obtener los saldos.');
        }
      } catch (error) {
        console.error('Error al consultar los saldos:', error);
      }
    },

    validarSaldoAFavor(saldos) {
      const elementosRegistrales = this.carritoOrdenado.filter(
        (tipo) => tipo.TipoElementosId === 1
      );

      // Si no hay elementos registrales, no ejecutar el resto del método
      if (!elementosRegistrales) {
        return;
      }

      console.log("saldos");


      let DBACARA = this.$db("ACARA");
      let DBMJ = this.$db("MJ");

      const saldoACARA = Math.abs(parseFloat(saldos[DBACARA]?.CurrentAccountBalance || 0));
      const saldoMJ = Math.abs(parseFloat(saldos[DBMJ]?.CurrentAccountBalance || 0));

      const subtotalMJ = elementosRegistrales
        .filter((item) => item.db === this.$db("MJ"))
        .reduce((acc, item) => acc + item.priceList.Price * item.unidades, 0);

      const subtotalACARA = elementosRegistrales
        .filter((item) => item.db === this.$db("ACARA"))
        .reduce((acc, item) => acc + item.priceList.Price * item.unidades, 0);

      const gastoEnvioMJ = elementosRegistrales
        .filter((item) => item.db === this.$db("MJ") && item.ItemCode === "FLE")
        .reduce((acc, item) => acc + item.priceList.Price * item.unidades, 0);

      const esValidoMJ = subtotalMJ <= saldoMJ;
      const esValidoACARA = (subtotalACARA + gastoEnvioMJ) <= saldoACARA;

      // Actualizar el estado en base a validaciones
      this.paymentMethods.forEach((tipo) => {
        if (tipo.code == 'SALDO') {
          tipo.disabled = !(esValidoACARA && esValidoMJ);
        }
      });
    },

    async calcularCostoFlete() {
      // Eliminar cualquier gasto de envío existente antes de recalcular
      this.carritoOrdenado = this.carritoOrdenado.filter((item) => item.ItemCode !== "FLE");

      const articulosConFlete = this.carrito.data.filter((item) => item.U_Flete === "tYES" && item.InventoryItem === true);

      // Si no hay artículos que paguen flete, salir de la función
      if (articulosConFlete.length === 0) {
        this.$store.state.carrito.data = this.carritoOrdenado;
        this.$store.commit("carrito/calcularTotales");
        return;
      }

      const articulosPorAlmacen = articulosConFlete.reduce((acc, item) => {
        const almacen = item.DefaultWarehouse;
        if (!acc[almacen]) {
          acc[almacen] = [];
        }
        acc[almacen].push(item);
        return acc;
      }, {});

      let cantidadEnviosTotal = 0;
      const precioUnitarioEnvio = this.$store.getters.getPrecioFlete;

      // Calcular la cantidad de envíos necesarios para cada almacén
      for (const warehouseCode in articulosPorAlmacen) {
        try {
          const response = await this.$axiosApi.getById(
            "warehousesByCode",
            `${warehouseCode}/`+this.$db("ACARA")
          );

          const U_UnidFleteC = response.data?.data?.U_UnidFleteC;
          if (!U_UnidFleteC) continue;

          const cantidadArticulos = articulosPorAlmacen[warehouseCode].reduce(
            (acc, item) => acc + item.unidades,
            0
          );

          cantidadEnviosTotal += Math.ceil(cantidadArticulos / U_UnidFleteC);

        } catch (error) {
          console.error(`Error al obtener U_UnidFleteC para ${warehouseCode}:`, error);
        }
      }

      const gastoFlete = {
        TipoElementosDesc: "Elementos Registrales",
        TipoElementosId: 1,
        ItemCode: "FLE",
        ItemName: "ENVIO X CORREO",
        ForeignName: "ENVIO X CORREO",
        priceList: { Price: precioUnitarioEnvio },
        unidades: cantidadEnviosTotal,
        db: this.$db("ACARA"),
        error: ""
      };

      this.carritoOrdenado.push(gastoFlete);

      // Actualizar el estado del carrito y recalcular totales
      this.$store.state.carrito.data = this.carritoOrdenado;
      this.$store.commit("carrito/calcularTotales");
    },

    async fetchAportesData() {
      try {
        let userState =
          this.$store.state.user.sapProperties.BPAddresses[1].State;
        let userPriceList = this.$store.state.user.sapProperties.PriceListNum;
        let userPartner = this.$store.state.user.sapProperties.Properties39;
        let userAdefa = this.$store.state.user.sapProperties.Properties41;
        let items = this.carrito.data.map((item) => item.ItemCode);

        //console.log(this.$store.state.user.sapProperties);

        let formSap = {
          items: {
            userState: userState, //reemplazar con los valores reales del user
            userPriceList: userPriceList,
            userPartner: userPartner, //reemplazar con los valores reales del user
            userAdefa: userAdefa, //reemplazar con los valores reales del user
            itemsID: items,
          },
        };
        const response = await this.$axiosApi.post(this.route, formSap);
        if (response.status != 200) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.data.data;

        return data;
      } catch (error) {
        console.error("Error en la conexión con la API:", error);
      }
    },
    async loadData() {
      this.isLoading = true;
      //aca tengo q llamar al endpoint para buscar la info d los aportes
      console.log("Conectando a la API para obtener datos del usuario...");

      // Llamamos al método fetchUserData y esperamos la respuesta
      const itemsAportes = await this.fetchAportesData();

      let dataCarrito = this.carrito.data;

      if (itemsAportes) {
        itemsAportes.forEach((element) => {
          //console.log(element);
          const indice = dataCarrito.findIndex(
            (carrito) => carrito.ItemCode === element.U_ArtOrigen
          );
          //creo un nuevo indice en el objeto para setear los aportes
          dataCarrito[indice].Aportes = element;
        });
        this.carrito.data = dataCarrito;
      }

      setTimeout(() => {


        this.carritoOrdenado = this.carrito.data
          .slice()
          .sort((a, b) => a.TipoElementosId - b.TipoElementosId);

        this.carritoTiposElementos = this.obtenerTiposElementos();

        this.mergedTiposElementosConStore(),

        //recorrer tipos de elementos si tienen metodopago seteado calcular segun metodo

        this.carritoTiposElementos.forEach((e) => {
            if (e.MetodoPago) {
              this.changeMethodPayment(e);
            }
        });

        this.isLoading = false;

        this.panelStates = this.carritoTiposElementos.map(() => false);

        this.calcularCostoFlete();

        this.$store.commit("carrito/calcularTotales");

        this.fetchSaldoAFavor();
      }, 400);
    },
    obtenerTiposElementos(){
      // Primero mapeamos el carrito para obtener solo TipoElementosId y TipoElementosDesc
        let tipoElementosArray = this.carritoOrdenado.map((item) => ({
          TipoElementosId: item.TipoElementosId,
          TipoElementosDesc: item.TipoElementosDesc,
          MetodoPago: "",
          Cheques:
            {acara:
              {
              banco:"",
              importe:"",
              vtoCheque:"",
              nroCheque:"",
              },


              mj:
              {
                banco:"",
                importe:"",
                vtoCheque:"",
                nroCheque:"",
              }

            }


        }));

        // Luego, eliminamos duplicados usando `filter` y `findIndex`

        return tipoElementosArray.filter( (item, index, self) => index === self.findIndex(
              (t) =>
                t.TipoElementosId === item.TipoElementosId &&
                t.TipoElementosDesc === item.TipoElementosDesc
            )
        );

    },
    mergedTiposElementosConStore(){



        // mergeo los tipos de elementos con los que tengo en el store
        const merged = [...this.$store.state.carrito.tipos, ... this.carritoTiposElementos].reduce((acc, current) => {
            const existing = acc.find(item => item.TipoElementosId === current.TipoElementosId);
            if (existing) {
                // Si ya existe, priorizar MetodoPago del store
                if (!existing.MetodoPago && current.MetodoPago) {
                    existing.MetodoPago = current.MetodoPago;
                }
            } else {
                acc.push(current);
            }
            return acc;
        }, []);
        this.$store.state.carrito.tipos = merged;
        this.carritoTiposElementos = merged;


    },

    dialogEliminar(itemId) {
      this.$set(this.dialogs, itemId, true);
    },
    dialogVaciar() {
      this.dialogs.carritoVaciar = true;
    },

    dialogValidate() {
      // Bandera para verificar si todos los métodos de pago están seleccionados
      let allMethodsSelected = true;
      //let hasChequeSelected = false;
      let validacionCheque =true;
      // Recorre los tipos de elementos en el carrito y verifica sus métodos de pago

      this.carritoTiposElementos.forEach((tipo) => {
        console.log(tipo.MetodoPago);
        if (!tipo.MetodoPago) {
          // Si algún método de pago no está seleccionado, marca la bandera y muestra el diálogo de validación
          allMethodsSelected = false;
        } else if (tipo.MetodoPago === "BANKNACION_CHEQUE") {
          // Si el método de pago seleccionado es 'Cheque', activa la bandera de cheque
          //hasChequeSelected = true;


          //si el total de acara >0
          //entonces pregunto si el banco e importe distinto de null
          if(this.$store.state.carrito.sumasPorTipo[tipo.TipoElementosId].subtotalDb[this.$db("ACARA")] >0)
          {
            if(tipo.Cheques.acara.banco =='' || tipo.Cheques.acara.importe == '' || (tipo.Cheques.acara.importe ==0)
          || (tipo.Cheques.acara.vtoCheque =='') || (tipo.Cheques.acara.nroCheque ==''))
            {
              validacionCheque =false;
            }
          }


          //si el total de mj >0
          //entonces pregunto si el banco e importe distinto de null

          if(this.$store.state.carrito.sumasPorTipo[tipo.TipoElementosId].subtotalDb[this.$db("MJ")] >0)
          {
            if(tipo.Cheques.mj.banco =='' || tipo.Cheques.mj.importe == '' || (tipo.Cheques.mj.importe ==0)
            || (tipo.Cheques.mj.vtoCheque =='') || (tipo.Cheques.mj.nroCheque =='')
          )
            {
              validacionCheque =false;
            }
          }



        }
      });
      //console.log(validacionCheque);


      // Si no todos los métodos de pago están seleccionados, muestra el diálogo de validación
      if (!allMethodsSelected) {
        this.dialogs.carritoValidateMetodosPago = true;
      }  else if(!validacionCheque){
        this.dialogs.validateCheque = true;
      }else{


        this.dialogs.carritoFacturas = true;
      }
    },

    isDelegation() {
      return (
        this.$store.state.user.sapProperties.Properties16 === true ||
        this.$store.state.user.sapProperties.Properties21 === true
      );
    },

    async storeFactura() {
      try {
       // console.log("datos de los items");
        //console.log(this.carritoTiposElementos);

        let formUserData = {
          cardCode: this.$store.state.user.sapProperties.CardCode,
          delegation: this.isDelegation(),
          uNamePortal: this.$store.state.user.sapProperties.CardCode,
          namePortal: this.$store.state.user.sapProperties.CardName,
        };
        let form = {
          userData: formUserData,
          elementsTypes: this.carritoTiposElementos,
          items: this.carrito.data,
        };

        const response = await this.$axiosApi.post(this.routeFactura, form);
        //console.log(response);
        if (response.status != 200) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response;

        return data;
      } catch (error) {
        console.error("Error en la conexión con la API:", error);
      }
    },

    async viewFacturas() {
      this.isLoading = true;
      this.loadingMsg = "Generando Facturas...";

      //aca tengo q llamar al endpoint para buscar la info d los aportes
      //console.log("Conectando a la API para registrar la factura...");
      //console.log(this.carrito);
      //console.log(this.carritoTiposElementos);
      // Llamamos al método storeFactura y esperamos la respuesta
      const responseFactura = await this.storeFactura();


      if (responseFactura.status == 200) {
        let dataFacturas = responseFactura.data.data;
        this.$router.push({
          name: "carrito_detalle_facturacion",
          params: { facturas: dataFacturas },
        });
        this.$store.commit("carrito/remove");
      }

    },

    carritoVaciar() {
      this.$store.commit("carrito/remove");
      this.dialogs.carritoVaciar = false;
      this.loadData();
      this.$forceUpdate();
    },

     removeItem(item) {
      this.$store.commit("carrito/removeItem", item);


      this.$delete(this.dialogs, item.ItemCode);
      this.loadData();
    },
    togglePanel(index) {
      this.$set(this.panelStates, index, !this.panelStates[index]);
    },

    togglePanelItem(index) {
      this.$set(this.panelStatesItem, index, !this.panelStatesItem[index]);
    },

    toggleCard(index) {
      // Alterna la expansión de la tarjeta
      this.$set(this.cardStates, index, !this.cardStates[index]);
    },
    toggleCardItem(indexItem) {
      // Alterna la expansión del ítem dentro de la tarjeta
      this.$set(
        this.cardStatesItem,
        indexItem,
        !this.cardStatesItem[indexItem]
      );
    },

    changeMethodPayment(tipo){

        this.isLoading = true;

        setTimeout(() => {
                //saldo
                this.eliminarGastos("GT",tipo.TipoElementosId);
                this.eliminarGastos("GB",tipo.TipoElementosId);


                let gasto_bancarioACARA =0;
                let gasto_bancarioMJ =0;
                let gasto_transferenciaACARA =0;
                let gasto_transferenciaMJ =0;

                this.cardStates[tipo.TipoElementosId-1] = true;//despliega el card despues de seleccionar el metodo de pago

                if(tipo.TipoElementosId==1 || tipo.TipoElementosId==3)
                {


                      if((tipo.MetodoPago == 'BTOB'))
                      {

                        this.carritoOrdenado.filter( (el) => el.TipoElementosId === tipo.TipoElementosId )
                                              .forEach(item => {

                              if(item.db ==this.$db("ACARA"))
                              {
                                  //elem registrales o aduana
                                  if (item.InventoryItem ) {
                                      gasto_transferenciaACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                  }

                                  //adquirir saldo ACARA
                                  if (item.ItemCode =="PA") {
                                      gasto_transferenciaACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                  }
                              }
                              if(item.db ==this.$db("MJ"))
                              {
                                  //elem registrales o aduana
                                  if (item.InventoryItem   ) {
                                      gasto_transferenciaMJ += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                      if(item.Aportes)
                                      {
                                        gasto_transferenciaACARA += item.Aportes.aporteItemPrice * item.unidades  * this.$store.state.config.gasto_transferencia;

                                      }
                                  }
                                  //adquirir saldo MJ
                                  if (item.ItemCode =="P2"  ) {
                                      gasto_transferenciaMJ += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_transferencia);

                                  }
                              }


                        });

                        let minGastoTransferencia = this.$store.state.config.gasto_transferencia_min;
                        if(gasto_transferenciaACARA < minGastoTransferencia && gasto_transferenciaACARA >0)
                        {
                          gasto_transferenciaACARA =minGastoTransferencia;
                        }

                        if(gasto_transferenciaMJ < minGastoTransferencia && gasto_transferenciaMJ >0)
                        {
                          gasto_transferenciaMJ =minGastoTransferencia;
                        }


                          if (gasto_transferenciaACARA  >0) {

                            // array minimo de gb hay que corroborar si es suficiente para
                            // la factura, sino habra q hacer un getItem GT


                              let gtACARA={
                                "TipoElementosDesc": tipo.TipoElementosDesc,
                                "TipoElementosId": tipo.TipoElementosId,
                                "ItemCode": "GT",
                                "ItemName": "Gastos Transferencia",
                                "ForeignName": "Gastos Transferencia",
                                "priceList":{"Price":gasto_transferenciaACARA},
                                "unidades":1,
                                "db":this.$db("ACARA"),
                                "error":""
                              }


                              this.carritoOrdenado.push(gtACARA);
                          }

                          if (gasto_transferenciaMJ  >0) {
                            let gtMJ={
                                "TipoElementosDesc": tipo.TipoElementosDesc,
                                "TipoElementosId": tipo.TipoElementosId,
                                "ItemCode": "GT",
                                "ItemName": "Gastos Transferencia",
                                "ForeignName": "Gastos Transferencia",
                                "priceList":{"Price":gasto_transferenciaMJ},
                                "unidades":1,
                                "db":this.$db("MJ"),
                                "error":""
                              }
                              this.carritoOrdenado.push(gtMJ);
                          }





                      }
                      else if( (tipo.MetodoPago == 'BANKNACION_CHEQUE'|| tipo.MetodoPago =='BANKNACION_EFECTIVO'))
                      {



                          let gasto_bancarioMinimo =   this.$store.state.config.gasto_bancario_min;;

                          this.carritoOrdenado.filter( (el) => el.TipoElementosId === tipo.TipoElementosId )
                                              .forEach(item => {
                              if(item.db ==this.$db("ACARA"))
                              {
                                gasto_bancarioACARA += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_bancario);
                              }
                          });

                          this.carritoOrdenado.filter( (el) => el.TipoElementosId === tipo.TipoElementosId )
                                              .forEach(item => {
                              if(item.db ==this.$db("MJ"))
                              {
                                gasto_bancarioMJ += (item.priceList.Price * item.unidades * this.$store.state.config.gasto_bancario);
                                if(item.Aportes)
                                {

                                  gasto_bancarioACARA += item.Aportes.aporteItemPrice * item.unidades  * this.$store.state.config.gasto_bancario;

                                }
                              }
                          });



                          if(gasto_bancarioACARA < gasto_bancarioMinimo && gasto_bancarioACARA >0)
                          {
                            gasto_bancarioACARA =gasto_bancarioMinimo;
                          }
                          if(gasto_bancarioMJ < gasto_bancarioMinimo && gasto_bancarioMJ >0)
                          {
                            gasto_bancarioMJ =gasto_bancarioMinimo;
                          }

                          if (gasto_bancarioACARA  >0) {

                            // array minimo de gb hay que corroborar si es suficiente para
                            // la factura, sino habra q hacer un getItem GB

                            let gbAcara={
                                  "TipoElementosDesc": tipo.TipoElementosDesc,
                                  "TipoElementosId": tipo.TipoElementosId,
                                  "ItemCode": "GB",
                                  "ItemName": "Gastos Bancarios",
                                  "ForeignName": "Gastos Bancarios",
                                  "priceList":{"Price":gasto_bancarioACARA},
                                  "unidades":1,
                                  "db":this.$db("ACARA"),
                                  "error":""
                                }


                              this.carritoOrdenado.push(gbAcara);
                          }

                          if (gasto_bancarioMJ  >0) {
                              let gbMJ={
                                "TipoElementosDesc": tipo.TipoElementosDesc,
                                "TipoElementosId": tipo.TipoElementosId,
                                "ItemCode": "GB",
                                "ItemName": "Gastos Bancarios",
                                "ForeignName": "Gastos Bancarios",
                                "priceList":{"Price":gasto_bancarioMJ},
                                "unidades":1,
                                "db":this.$db("MJ"),
                                "error":""
                              }
                              this.carritoOrdenado.push(gbMJ);
                          }


                      }
                }

                  this.$store.state.carrito.tipos = this.carritoTiposElementos;

                  this.$store.state.carrito.data =this.carritoOrdenado;
                  this.$store.commit("carrito/calcularTotales");
                  this.$forceUpdate();

                  this.isLoading = false;
              },400);


    },

    eliminarGastos(tipoGasto,tipoElementoId){
      let existingItemIndex = this.carritoOrdenado.findIndex(el => el.ItemCode === tipoGasto && el.db ===this.$db("ACARA") && el.TipoElementosId ===tipoElementoId);

      if (existingItemIndex !== -1) {
           this.carritoOrdenado.splice(existingItemIndex, 1);
      }
        let existingItemIndexMJ = this.carritoOrdenado.findIndex(el => el.ItemCode === tipoGasto && el.db ===this.$db("MJ") && el.TipoElementosId ===tipoElementoId);

      if (existingItemIndexMJ !== -1) {

          this.carritoOrdenado.splice(existingItemIndexMJ, 1);
      }

    },

      capitalizeFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },



  },
  watch: {},
};
</script>

<style>
.custom-btn {
  text-transform: none;
  font-family: inherit;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

.small-select .v-input__control {
  min-height: 18px !important; /* Altura total del select */
}

.small-select .v-label {
  font-size: 14px !important; /* Tamaño de la fuente del label */

  line-height: 1;
}

.fondo-blanco {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.v-list-item--disabled {
  color: gray !important;
  cursor: not-allowed !important;
  opacity: 0.6 !important;
}
</style>

